<template>
  <li class="text-base lg:text-xl transition duration-300 ease-in-out hover:scale-105">
    <slot/>
  </li>
</template>

<script>
export default {
  name: "HomepageLi",
}
</script>
